import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { MaxWidthWrapper } from '../../pages/membership';
import { Uppercase as Eyebrow } from '../../ui/TypographyStyles';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import Heading2 from '../../ui/Heading2';
import Checkmark from '../../images/icons/white-checkmark.svg';
import Dog from '../../images/pages/plans/dog-orange-background.webp';

const Container = styled.section`
  background-image: url(${Dog});
  background-position: left 66.5% top -400px;
  background-repeat: no-repeat;
  background-size: 2850px;
  background-color: #ed923c;
  width: 100%;
  padding: 140px 100px;
  margin-bottom: 20px;

  @media (min-width: 1680px) {
    background-position: left 70% top -340px;
  }

  @media screen and (max-width: 1200px) {
    background-size: 2300px;
    background-position: left 70% top -220px;
  }

  @media ${BREAKPOINTS.TABLET} {
    padding: 90px 40px;
    background-size: 2000px;
    background-position: left 70% top -300px;
  }

  @media (max-width: 820px) {
    background-position: left 65% top -220px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    padding: 80px 20px 0;
  }

  @media (max-width: 540px) {
    padding: 80px 20px;
    margin-bottom: 12px;
    background-size: 2400px;
    background-position: left 67% top -260px;
  }
`;

const Header = styled(Heading2)`
  color: ${COLORS.WHITE};
`;

const List = styled.ul`
  color: ${COLORS.WHITE};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  margin-top: 67.5%;

  li {
    list-style-image: url(${Checkmark});
    list-style-position: inside;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0px;

    span {
      position: relative;
      top: -5px;
      margin-left: 25px;
      @media ${BREAKPOINTS.MOBILE} {
        margin-left: 12px;
      }
    }
  }

  @media ${BREAKPOINTS.DESKTOP} {
    max-height: 150px;
  }

  @media (min-width: 1680px) {
    margin-top: 70%;
  }

  @media ${BREAKPOINTS.TABLET} {
    margin-top: 55%;
  }

  @media (max-width: 1024px) and (min-width: 769px) {
    height: 150px;
  }

  @media (max-width: 820px) {
    margin-top: 70%;
  }

  @media ${BREAKPOINTS.MOBILE} {
    flex-wrap: nowrap;
  }

  @media (max-width: 540px) {
    margin-top: 180%;
  }
`;

interface Props {
  header: string;
  perks: string[];
}

const Benefits = ({ header, perks }: Props) => {
  return (
    <Container>
      <MaxWidthWrapper>
        <Eyebrow
          css={css`
            color: ${COLORS.WHITE};
            margin-bottom: 40px;
            @media ${BREAKPOINTS.MOBILE} {
              display: none;
            }
          `}
        >
          Veterinary care done right
        </Eyebrow>
        <Eyebrow
          css={css`
            display: none;
            @media ${BREAKPOINTS.MOBILE} {
              display: block;
              color: ${COLORS.WHITE};
              margin-bottom: 24px;
            }
          `}
        >
          Vet care done right
        </Eyebrow>
        <Header>{header}</Header>
        <List>
          {perks.map(perk => (
            <li key={perk}>
              <span>{perk}</span>
            </li>
          ))}
        </List>
      </MaxWidthWrapper>
    </Container>
  );
};

export default Benefits;
