import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from './seo';

const MembershipSEO = () => {
  const { site, OGImage, contentfulPagesSinglePlanMembership } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        OGImage: file(relativePath: { eq: "link-preview-image-02.jpg" }) {
          childImageSharp {
            fixed(width: 1000) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        contentfulPagesSinglePlanMembership {
          schemaMarkup {
            _context
            _type
            mainEntity {
              _type
              name
              acceptedAnswer {
                _type
                text
              }
            }
          }
        }
      }
    `
  );
  const schema = contentfulPagesSinglePlanMembership.schemaMarkup;

  return (
    <SEO
      title='Membership and Pricing'
      description='Small Door membership offers best-in-class veterinary care for your pet, with 24/7 telehealth, same or next-day appointments &amp; more time with your doctor.'
      image={site.siteMetadata.siteUrl + OGImage.childImageSharp.fixed.src}
      schema={schema}
    />
  );
};

export default MembershipSEO;
