import React, { useState, useEffect, useCallback } from 'react';
import Img from 'gatsby-image';
import { FixedObject } from 'gatsby-image';
import useEmblaCarousel from 'embla-carousel-react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ParagraphMedium from '../../ui/ParagraphMedium';
import ReviewStar from '../../images/icons/review-star-orange.svg';
import { Name, SubtitleSemibold, Uppercase } from '../../ui/TypographyStyles';

const Container = styled.section`
  background-color: ${COLORS.NEW_LIGHT_BEIGE};
  padding: 100px 0;
  margin-bottom: 20px;

  @media ${BREAKPOINTS.TABLET} {
    padding: 80px 0;
  }
  @media ${BREAKPOINTS.MOBILE} {
    margin-bottom: 12px;
  }
`;

const Viewport = styled.div`
  overflow: hidden;
  width: 100%;
  padding-right: 10px;
`;

const CardContainer = styled.div`
  padding: 0;
  display: flex;
  @media ${BREAKPOINTS.MOBILE} {
    cursor: grab;
  }
`;

const ReviewCard = styled.div`
  background-color: ${COLORS.WHITE};
  padding: 60px;
  margin: 0 6px;
  width: 615px;
  flex-shrink: 0;

  @media ${BREAKPOINTS.MOBILE} {
    padding: 32px;
    width: 350px;
    margin: 0 4px;
    display: flex;
    flex-direction: column;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${BREAKPOINTS.MOBILE} {
    margin-top: auto;
  }
`;

const Star = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(${ReviewStar});
`;

const ReviewerName = styled(Name)`
  margin: 0;
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
  cursor: pointer;
  border: none;
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background: ${COLORS.BLACK};
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16));
  transition: all 300ms ease;

  @media ${BREAKPOINTS.TABLET} {
    width: 48px;
    height: 48px;
  }

  svg {
    fill: ${COLORS.WHITE};

    @media ${BREAKPOINTS.TABLET} {
      right: 24px;
      width: 19px;
    }
  }

  &:hover {
    background-color: #505050;
  }
`;

const NextButton = styled(Button)`
  right: 40px;

  @media ${BREAKPOINTS.MOBILE} {
    display: none;
  }
`;

const PrevButton = styled(Button)`
  left: 40px;

  @media ${BREAKPOINTS.MOBILE} {
    display: none;
  }
`;

interface Testimonial {
  name: string;
  header: string;
  review: string;
  logo: FixedObject;
  alt: string;
}

interface Props {
  reviews: Testimonial[];
}

const TestimonialCarouselTemplate = ({ reviews }: Props) => {
  const [emblaRef, embla] = useEmblaCarousel({
    align: 'center',
    loop: true,
    slidesToScroll: 2,
    startIndex: 1,
    breakpoints: {
      '(max-width: 820px)': { slidesToScroll: 1 },
    },
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on('select', onSelect);
    onSelect();
  }, [embla, onSelect]);

  return (
    <Container>
      <Uppercase
        css={css`
          text-align: center;
          margin-bottom: 80px;
          @media ${BREAKPOINTS.MOBILE} {
            margin-bottom: 60px;
          }
        `}
      >
        Testimonials
      </Uppercase>
      <div
        css={css`
          position: relative;
        `}
      >
        <Viewport ref={emblaRef}>
          <CardContainer>
            {reviews.map(review => (
              <ReviewCard key={review.name}>
                <span>{new Array(5).fill(<Star />)}</span>
                <SubtitleSemibold
                  css={css`
                    margin-top: 32px;
                  `}
                >
                  {review.header}
                </SubtitleSemibold>
                <ParagraphMedium
                  css={css`
                    height: 168px;
                    @media ${BREAKPOINTS.MOBILE} {
                      margin-bottom: 40px;
                      height: fit-content;
                      height: -moz-fit-content;
                    }
                  `}
                >
                  {review.review}
                </ParagraphMedium>
                <Footer>
                  <ReviewerName>{review.name}</ReviewerName>
                  <Img fixed={review.logo} alt={review.alt} />
                </Footer>
              </ReviewCard>
            ))}
          </CardContainer>
        </Viewport>
        <PrevButton onClick={scrollPrev} disabled={!prevBtnEnabled}>
          <ChevronLeftIcon fontSize='large' />
        </PrevButton>
        <NextButton onClick={scrollNext} disabled={!nextBtnEnabled}>
          <ChevronRightIcon fontSize='large' />
        </NextButton>
      </div>
    </Container>
  );
};

export default TestimonialCarouselTemplate;
