import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import CarouselDynamic from '../carousel/CarouselDynamic';

const PicsCarousel = () => {
  const data = useStaticQuery(graphql`
    query {
      member: file(
        relativePath: {
          eq: "pages/plans/low-res/2024_02_SmallDoor_MembershipPage_CarouselUpdate-02.webp"
        }
      ) {
        childImageSharp {
          fixed(height: 640, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      nurse: file(
        relativePath: {
          eq: "pages/plans/low-res/2024_02_SmallDoor_MembershipPage_CarouselUpdate-01.webp"
        }
      ) {
        childImageSharp {
          fixed(height: 640, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      frontDesk: file(
        relativePath: {
          eq: "pages/plans/low-res/220622_GCanoxSmallDoorVet_UWS_Shot_06_Front_Desk_Corner-02.jpg"
        }
      ) {
        childImageSharp {
          fixed(height: 640, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      baoBao: file(
        relativePath: { eq: "pages/plans/low-res/Bao_Bao_0853.jpg" }
      ) {
        childImageSharp {
          fixed(height: 640, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      membersPets: file(
        relativePath: { eq: "pages/plans/low-res/Garbanzo_1087.jpg" }
      ) {
        childImageSharp {
          fixed(height: 640, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      percy: file(
        relativePath: { eq: "pages/plans/low-res/SDV_Staff_0947.jpg" }
      ) {
        childImageSharp {
          fixed(height: 640, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      piper: file(
        relativePath: { eq: "pages/plans/low-res/SDV_Staff_1000.jpg" }
      ) {
        childImageSharp {
          fixed(height: 640, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      willis: file(
        relativePath: { eq: "pages/plans/low-res/Willis_0737.jpg" }
      ) {
        childImageSharp {
          fixed(height: 640, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      mobile1: file(
        relativePath: {
          eq: "pages/plans/low-res/2024_02_SmallDoor_MembershipPage_CarouselUpdate-02.webp"
        }
      ) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 400, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile2: file(
        relativePath: {
          eq: "pages/plans/low-res/2024_02_SmallDoor_MembershipPage_CarouselUpdate-01.webp"
        }
      ) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 300, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile3: file(
        relativePath: {
          eq: "pages/plans/low-res/220622_GCanoxSmallDoorVet_UWS_Shot_06_Front_Desk_Corner-02.jpg"
        }
      ) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 300, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile4: file(
        relativePath: { eq: "pages/plans/low-res/Bao_Bao_0853.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 300, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile5: file(
        relativePath: { eq: "pages/plans/low-res/Garbanzo_1087.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 300, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile6: file(
        relativePath: { eq: "pages/plans/low-res/SDV_Staff_0947.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 300, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile7: file(
        relativePath: { eq: "pages/plans/low-res/SDV_Staff_1000.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 300, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile8: file(
        relativePath: { eq: "pages/plans/low-res/Willis_0737.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 300, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <CarouselDynamic
        type='desktop'
        maxHeight='640px'
        imagesDesktop={[
          {
            fixed: data.piper.childImageSharp.fixed,
            alt: 'Member and her dog in the examining room',
          },
          {
            fixed: data.baoBao.childImageSharp.fixed,
            alt:
              'Veterinarian examines Baobao the cat while his owner holds him.',
          },
          {
            fixed: data.frontDesk.childImageSharp.fixed,
            alt:
              'Small Door front desk where member is chatting with recepcionist.',
          },
          {
            fixed: data.nurse.childImageSharp.fixed,
            alt: 'Vet nurse walking with dog in hallway.',
          },
          {
            fixed: data.membersPets.childImageSharp.fixed,
            alt: 'Members with their pets in the lounge area.',
          },
          {
            fixed: data.percy.childImageSharp.fixed,
            alt: 'Veterinarian examines Percy the dog.',
          },
          {
            fixed: data.member.childImageSharp.fixed,
            alt: 'Member with her dog in the lounge area.',
          },
          {
            fixed: data.willis.childImageSharp.fixed,
            alt:
              'Veterinarian in the examining room with members and their dog.',
          },
        ]}
      />
      <CarouselDynamic
        type='mobile'
        maxHeight='300px'
        imagesMobile={[
          {
            fluid: data.mobile7.childImageSharp.fluid,
            alt: 'Member and her dog in the examining room',
          },
          {
            fluid: data.mobile4.childImageSharp.fluid,
            alt:
              'Veterinarian examines Baobao the cat while his owner holds him.',
          },
          {
            fluid: data.mobile3.childImageSharp.fluid,
            alt:
              'Small Door front desk where member is chatting with recepcionist.',
          },
          {
            fluid: data.mobile2.childImageSharp.fluid,
            alt: 'Vet nurse walking with dog in hallway.',
          },
          {
            fluid: data.mobile5.childImageSharp.fluid,
            alt: 'Members with their pets in the lounge area.',
          },
          {
            fluid: data.mobile6.childImageSharp.fluid,
            alt: 'Veterinarian examines Percy the dog.',
          },
          {
            fluid: data.mobile1.childImageSharp.fluid,
            alt: 'Member with her dog in the lounge area.',
          },
          {
            fluid: data.mobile8.childImageSharp.fluid,
            alt:
              'Veterinarian in the examining room with members and their dog.',
          },
        ]}
      />
    </>
  );
};

export default PicsCarousel;
