import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import { MaxWidthWrapper, JoinCTA } from '../../pages/membership';
import Momo from '../../images/pages/plans/momo.webp';
import { TitleMediumSerif } from '../../ui/TypographyStyles';

const Wrapper = styled.div`
  background-image: url(${Momo});
  background-position: 10% 8%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 80vh;
  padding-top: 120px;

  @media ${BREAKPOINTS.TABLET} {
    padding-top: 90px;
    padding-left: 40px;
    background-position: 20% 15%;
  }

  @media ${BREAKPOINTS.MOBILE} {
    display: none;
  }
`;

const CTAWrapper = styled.div`
  @media screen and (max-width: 1434px) {
    padding-left: 50px;
  }

  @media screen and (max-width: 1242px) {
    padding-left: 100px;

    @media ${BREAKPOINTS.TABLET} {
      padding-left: 10px;
    }
  }
`;

const Text = styled(TitleMediumSerif)`
  max-width: 520px;

  @media screen and (max-width: 1250px) {
    max-width: 500px;
  }

  @media ${BREAKPOINTS.TABLET} {
    max-width: 410px;
  }
`;

const Mobile = styled.section`
  display: none;
  @media ${BREAKPOINTS.MOBILE} {
    display: block;
  }
`;

const Container = styled.div`
  background-color: ${COLORS.GREEN};
  padding: 80px 20px;
`;

const MobileText = styled.p`
  font-family: Adonis;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -1px;
  margin-bottom: 40px;
  max-width: 330px;
  color: ${COLORS.WHITE};
`;

interface Props {
  text: string;
}

const Footer = ({ text }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      momoCrop: file(relativePath: { eq: "pages/plans/momo-cropped.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <Wrapper>
        <MaxWidthWrapper>
          <CTAWrapper>
            <Text>{text}</Text>
            <JoinCTA />
          </CTAWrapper>
        </MaxWidthWrapper>
      </Wrapper>

      <Mobile>
        <Container>
          <MobileText>{text}</MobileText>
          <JoinCTA />
        </Container>
        <Img
          fluid={data.momoCrop.childImageSharp.fluid}
          alt='Member with his cat at the front desk.'
        />
      </Mobile>
    </>
  );
};

export default Footer;
