import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FixedObject } from 'gatsby-image';
import TestimonialCarouselTemplate from '../carousel/TestimonialCarouselTemplate';

interface Review {
  name: string;
  header: string;
  review: string;
  logo: FixedObject;
  alt: string;
}

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      google: file(relativePath: { eq: "pages/plans/google.png" }) {
        childImageSharp {
          fixed(width: 70, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      yelp: file(relativePath: { eq: "pages/plans/yelp.png" }) {
        childImageSharp {
          fixed(width: 70, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const reviews: Review[] = [
    {
      name: 'Ranu R.',
      header: 'Love Small Door vet!',
      review:
        'The practice is clean and comfortable, the doctors and techs are great, and we love that they have a supporting app for quick questions. The membership model makes a lot of sense, especially for new dog owners! Highly recommend.',
      logo: data.google.childImageSharp.fixed,
      alt: 'Google logo',
    },
    {
      name: 'Christine B.',
      header: "The best vet experience I've ever had!",
      review:
        'Staff is so helpful and friendly, got an appointment same day when I was worried about my new pup, doctor/nurses very professional and informative, and the office itself just has such a great vibe. So glad I found this place.',
      logo: data.yelp.childImageSharp.fixed,
      alt: 'Yelp logo',
    },
    {
      name: 'Faith L.',
      header: 'Small Door is an amazing service!',
      review:
        "The doctors are caring and take their time to assess all issues with your pet. I left my first appointment with them more educated on my dog's issues than any other vet apt! Their pricing is on point and continue to surprise me with the level of service and accommodation they provide.",
      logo: data.google.childImageSharp.fixed,
      alt: 'Google logo',
    },
    {
      name: 'Mia H.',
      header: 'Obsessed is an understatement.',
      review:
        'Their app allows you 24/7 access to speak with a member of their team! Small Door literally spent hours with me on their app texting at 4 a.m. Appointment times are constantly available and they are always accommodating for any last minute appointment needs.',
      logo: data.google.childImageSharp.fixed,
      alt: 'Google logo',
    },
    {
      name: 'Michelle R.',
      header: 'Hip, modern aesthetic.',
      review:
        'Small Door boasts extremely attentive and helpful doctors and staff, convenient communication and scheduling (including via their own app), easy billing with email receipts, and- my personal favorite- very transparent pricing. Two paws up from my dog, too.',
      logo: data.yelp.childImageSharp.fixed,
      alt: 'Yelp logo',
    },
  ];

  return <TestimonialCarouselTemplate reviews={reviews} />;
};

export default Testimonials;
