import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import { Uppercase } from '../../ui/TypographyStyles';

const Container = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;

  @media ${BREAKPOINTS.MOBILE} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const Nav = styled.nav`
  max-width: fit-content;
  margin: 0 auto;
  text-align: center;
`;

const LocationContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 0px 0px;
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const LocationLink = styled(Link)`
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  color: ${COLORS.NEW_ORANGE};

  &:hover {
    color: #c46621;

    span {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        #d6d5d8;
    }
  }

  @media ${BREAKPOINTS.MOBILE} {
    font-size: 24px;
    margin-right: 60px;
  }

  @media (max-width: 600px) {
    margin-right: 32px;
  }
`;

const ComingSoonPill = styled.span`
  background-color: ${COLORS.SECONDARY_GRAY};
  text-transform: capitalize;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 12px;
  color: ${COLORS.BLACK};
  font-weight: 600;
  display: inline-block;
  line-height: 12px;
  letter-spacing: 0.5px;
  position: relative;
  top: -4px;
`;

const LocationsBanner = () => {
  return (
    <Container>
      <Nav>
        <Uppercase
          css={css`
            margin-bottom: 30px;
            @media ${BREAKPOINTS.MOBILE} {
              margin-bottom: 14px;
            }
          `}
        >
          Locations in
        </Uppercase>
        <LocationContainer>
          <LocationLink
            to='/locations/boston'
            css={css`
              margin-right: 64px;
              @media ${BREAKPOINTS.MOBILE} {
                margin-left: 32px;
              }
            `}
          >
            Boston
          </LocationLink>
          <div>
            <LocationLink
              to='/locations/new-york-city'
              css={css`
                margin-right: 64px;
              `}
            >
              New&nbsp;York&nbsp;City
            </LocationLink>
          </div>
          <LocationLink
            to='/locations/washington-dc'
            css={css`
              display: flex;
              @media ${BREAKPOINTS.MOBILE} {
                margin-right: 32px;
              }
            `}
          >
            <div style={{ marginRight: '10px' }}>Washington&nbsp;DC&nbsp;</div>
          </LocationLink>
        </LocationContainer>
      </Nav>
    </Container>
  );
};

export default LocationsBanner;
